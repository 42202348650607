import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux';

import user from './features/user';
import userTable from './features/userTable';


const reducer = combineReducers({
    user,
    userTable
  })

export default configureStore({
  reducer
})