import React from 'react';
import { useDispatch } from 'react-redux';
import { 
  CHeader, CContainer, CHeaderBrand, CButton
} from '@coreui/react';
import { logout } from '../../auth';

const Header = () => {

  return (
    <CHeader position="sticky" className="mb-4 bg-dark">
      <CContainer fluid>
        <CHeaderBrand>Dashboard</CHeaderBrand>
        <CButton 
          color="primary"
          onClick={() => {
            logout();
          }}
        >
          Log Out
        </CButton>
      </CContainer>
    </CHeader>
  )
}

export default Header;