import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import {
  CContainer
} from '@coreui/react';
import UserTable from './UserTable';
import { getTable, getUsers } from '../../../store/features/userTable';


const Users = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTable())
    dispatch(getUsers())
  }, []);


  return (
    <CContainer className="d-flex h-100">
        <UserTable />
    </CContainer>
  )
}

export default Users;