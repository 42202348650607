import { Plugin } from '@devexpress/dx-react-core';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { DragDropProvider } from '@devexpress/dx-react-grid-material-ui';
import moment from 'moment';
import React from 'react';

const DateFormatter = ({ value }) => {
  return moment
    .utc(value, 'YYYY-MM-DDThh:mm:ss')
    .local()
    .format('YYYY-MM-DD HH:mm:ss');
}

const TableProviders = () => {
  return (
    <Plugin>
      <DataTypeProvider
        for={[
          'username',
          'email',
          'occupation',
          'region',
          'phone_number',
          'sub_level',
          'total_jobs',
          'jobs_passed',
          'jobs_failed'
        ]}
        availableFilterOperations={[]}
      />
      <DragDropProvider />
      <DataTypeProvider
        for={['id']}
        availableFilterOperations={['contains', 'greaterThanOrEqual', 'lessThanOrEqual']}
      />
      <DataTypeProvider
        for={[
          'created_at',
          'last_active'
        ]}
        formatterComponent={DateFormatter}
        availableFilterOperations={['contains', 'greaterThan', 'lessThan']}
      />
    </Plugin>
  )
}

export default TableProviders;