import React from 'react';
import {
  CRow, CCol
} from '@coreui/react'
import JobsWeekly from '../Widgets/JobsWeekly';
import WAUs from '../Widgets/WAUs';


const Home = () => {
  return (
    <CRow>
      <CCol sm={6}>
        <WAUs />
      </CCol>
      <CCol sm={6}>
        <JobsWeekly/>
      </CCol>
    </CRow>
  )
}

export default Home;