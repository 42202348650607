import React from 'react';
import { 
  CButton
} from '@coreui/react';
import { DownloadInput } from '../../../services/Downloads';


const UserExport = () => {
    return (
        <div>
          <CButton 
            color="primary"
            position='fixed'
            unfoldable={false}
            visible={true}
            onClick={() => {
              DownloadInput();
            }}
          >
            Download CSV
          </CButton>        
        </div>
    )
  }

  export default UserExport;