import Axios from "axios";

const OpenFile = (headers, data) => {
    const objectURL = window.URL.createObjectURL(data);
    let filename = headers['content-disposition'].split('filename="')[1].slice(0, -1)
    let templink = document.createElement('a');
    templink.href = objectURL;
    templink.download = filename
    templink.click();
    window.URL.revokeObjectURL(objectURL)
    templink.remove()
}

export const DownloadInput = () => {
    Axios.get(
      "/api/admin/users/xml", 
      { responseType: 'blob' }
    )
    .then(res => {
      OpenFile(res.headers, res.data)
    })
    .catch(err => {
      console.log(err)
    })
  }