import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, useHistory, withRouter } from "react-router-dom";

const SecureRoute = ({ component: Component, ...props }) => {

  const history = useHistory();
  const { uid } = useSelector(state => state.user)

  useEffect(() => {
    if (!uid) {
      history.push("/login");
    }
  }, [uid])

  if (!uid) {
    return (
      <Route
        render={() => {
          history.push("/login");
        }}
      />
    );
  }
  return (
    <Route
      render={rp => (
        <div style={{ 
          display: "flex",
          flexFlow: "column",
          height: "100vh"
        }}>
          <Component {...props} {...rp} />
        </div>
      )}
    />
  );
};

export default withRouter(SecureRoute);
