import React from "react";

const SignIn = ({
  username,
  setUsername,
  password,
  setPassword,
  handleSubmit,
}) => {
  return (
    <div className="form sign-in">
      <h2>Daisy.ai Admin</h2>
      <label>
        <span>Email</span>
        <input
          id="email-input"
          type="text"
          value={username}
          onChange={e => setUsername(e.target.value)}
        />
      </label>
      <label>
        <span className="password">Password</span>
        <input
          id="password-input"
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
      </label>
      <button type="button" className="submit" onClick={handleSubmit}>
        Sign In
      </button>
    </div>
  );
};

export default SignIn;
