import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import SignIn from "./_SignIn";
import { login } from "../../auth";

const Login = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const { uid } = useSelector(state => state.user);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const handleSubmit = () => {
    login(username, password)
  };

  useEffect(() => {
    if (uid) {
      history.push('/admin/')
    }
  }, [uid])

  return (
    <>
      <SignIn
        {...{
          username,
          setUsername,
          password,
          setPassword,
          handleSubmit
        }}
      />
    </>
  );
};

export default Login;
