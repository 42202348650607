// Slice

import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: localStorage.getItem('user'),
    token: localStorage.getItem('jwt_token'),
    uid: null
  },
  reducers: {
    setUserUID: (state, action) => {
      state.uid = action.payload;
    },
  },
});

export default userSlice.reducer;

// Actions

const { setUserUID } = userSlice.actions;

export const setUID = (uid) => dispatch => {
  dispatch(setUserUID(uid))
}
