import { 
  FilteringState, IntegratedFiltering, 
  IntegratedSorting, SortingState 
} from "@devexpress/dx-react-grid";
import { 
  TableHeaderRow, Grid, TableColumnResizing, TableColumnReordering, 
  TableColumnVisibility, ColumnChooser, Toolbar, TableFixedColumns, 
  VirtualTable, TableSelection, TableFilterRow
} from "@devexpress/dx-react-grid-material-ui";
import { makeStyles, Paper } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
  updateFilters, updateHidden, updateOrder, updateWidths
} from '../../../store/features/userTable';
import TableProviders from './TableProviders';

const useStyles = makeStyles((theme) => ({
  body: {
    flex: '1 1 100%',
    minHeight: 0,
    margin: theme.spacing(2),
    marginTop: theme.spacing(0)
  },
}));

function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}

const UserTable = () => {

  const classes = useStyles()

  const { table, users } = useSelector((state) => state.userTable);
  const dispatch = useDispatch();

  const boxRef = useRef(null)

  const rows = users;

  const leftColumns = ['id'];
  const rightColumns = [];

  const columnExtensions = [
    { columnName: 'id', align: 'left' },
    { columnName: 'username', align: 'left' },
    { columnName: 'email', align: 'left' },
    { columnName: 'occupation', align: 'left' },
    { columnName: 'region', align: 'left' },
    { columnName: 'phone_number', align: 'left' },
    { columnName: 'created_at', align: 'left' },
    { columnName: 'last_active', align: 'left' },
    { columnName: 'sub_level', align: 'left' },
    { columnName: 'total_jobs', align: 'left' },
    { columnName: 'jobs_passed', align: 'left' },
    { columnName: 'jobs_failed', align: 'left' },
  ]
  const filterStateExtensions = []

  const [height, setHeight] = useState(200);

  useEffect(() => {
    const debouncedResize = debounce(function handleResize() {
      if (boxRef) {
        setHeight(600)
      }
    }, 1000)

    debouncedResize()
    window.addEventListener('resize', debouncedResize)

    return () => {
      window.removeEventListener('resize', debouncedResize)
    }
  }, []);

  return (
    <div className={classes.body} ref={boxRef}  >
      <Paper>
        <Grid
          rows={rows}
          columns={table.columns}
        >
          <TableProviders />
          <SortingState 
            defaultSorting={[
              { columnName: 'id', direction: 'desc' }
            ]}
          />
          <FilteringState 
            filters={ table.filters }
            onFiltersChange={
              filter => dispatch(updateFilters(filter))
            }
            columnExtensions={filterStateExtensions}
          />
          <IntegratedFiltering />
          <IntegratedSorting />
          <VirtualTable 
            height={height}
            columnExtensions={columnExtensions}
          />

          <TableColumnReordering 
            order={ table.columnsOrder }
            onOrderChange={
              order => dispatch(updateOrder(order))
            }
          />
          <TableColumnResizing 
            columnWidths={ table.columnsWidth }
            onColumnWidthsChange={
              sorting => dispatch(updateWidths(sorting))
            }
            resizingMode={'widget'}
          />
          <TableHeaderRow showSortingControls />
          <TableFilterRow 
            showFilterSelector
          />
          <TableColumnVisibility
            hiddenColumnNames={ table.columnsHidden }
            onHiddenColumnNamesChange={
              hidden => dispatch(updateHidden(hidden))
            }
          />
          <TableFixedColumns
            leftColumns={leftColumns}
            rightColumns={rightColumns}
          />
          <Toolbar/>
          <ColumnChooser />
        </Grid>
      </Paper>
    </div>
  )

}

export default UserTable;