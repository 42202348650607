import React from "react";
import Routes from "./components/Routes/Routes";
import '@coreui/coreui/dist/css/coreui.min.css';
import './scss/style.scss';

const App = () => {
  return (
    <Routes />
  );
}
export default App;
