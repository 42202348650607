// Slice

import { createSlice } from '@reduxjs/toolkit';
import Axios from 'axios';

export const userTableSlice = createSlice({
  name: 'dashboard',
  initialState: {
    selected: [],
    users: [],
    table: {
      columns: [
        { name: 'id', title: 'ID'},
        { name: 'username', title: 'Username' },
        { name: 'email', title: 'Email' },
        { name: 'occupation', title: 'Occupation' },
        { name: 'region', title: 'Region' },
        { name: 'phone_number', title: 'Phone Number' },
        { name: 'created_at', title: 'Sign Up Date' },
        { name: 'last_active', title: 'Last Active' },
        { name: 'sub_level', title: 'Sub Level'},
        { name: 'total_jobs', title: 'Total Jobs' },
        { name: 'jobs_passed', title: 'Jobs Passed' },
        { name: 'jobs_failed', title: 'Jobs Failed'},
      ],
      columnsWidth: [
        { columnName: 'id', width: 100 },
        { columnName: 'username', width: 100 },
        { columnName: 'email', width: 100 },
        { columnName: 'occupation', width: 100 },
        { columnName: 'region', width: 100 },
        { columnName: 'phone_number', width: 100 },
        { columnName: 'created_at', width: 100 },
        { columnName: 'last_active', width: 100 },
        { columnName: 'sub_level', width: 100 },
        { columnName: 'total_jobs', width: 100 },
        { columnName: 'jobs_passed', width: 100 },
        { columnName: 'jobs_failed', width: 100 },
      ],
      columnsHidden: [],
      columnsOrder: [
        'id',
        'username',
        'email',
        'occupation',
        'region',
        'phone_number',
        'created_at',
        'last_active',
        'sub_level',
        'total_jobs',
        'jobs_passed',
        'jobs_failed'
      ],
      filters: [],
    }
  },
  reducers: {
    setWidths: (state, action) => {
      state.table.columnsWidth = action.payload;
      localStorage.setItem('table', JSON.stringify(state.table));
    },
    setOrder: (state, action) => {
      state.table.columnsOrder = action.payload;
      localStorage.setItem('table', JSON.stringify(state.table));
    },
    setHidden: (state, action) => {
      state.table.columnsHidden = action.payload;
      localStorage.setItem('table', JSON.stringify(state.table));
    },
    setFilters: (state, action) => {
      state.table.filters = action.payload;
      localStorage.setItem('table', JSON.stringify(state.table));
    },
    loadTable: (state, action) => {
      if (localStorage.getItem('table')) {
        let table = JSON.parse(localStorage.getItem('table'));
        if (table.columns.length === state.table.columns.length) {
          state.table = table;
        }
      }
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    }
  },
});

export default userTableSlice.reducer;

// Actions

const { 
  setWidths,
  setOrder,
  setHidden,
  setFilters,
  loadTable,
  setUsers
} = userTableSlice.actions;

export const updateWidths = (sorting) => dispatch => {
  dispatch(setWidths(sorting))
}

export const updateOrder = (order) => dispatch => {
  dispatch(setOrder(order))
}

export const updateHidden = (hidden) => dispatch => {
  dispatch(setHidden(hidden))
}

export const updateFilters = (filters) => dispatch => {
  dispatch(setFilters(filters))
}

export const getTable = () => dispatch => {
  dispatch(loadTable())
}

export const getUsers = () => dispatch => {
  Axios.get('/api/admin/users')
    .then(res => {
      dispatch(setUsers(res.data))
    })
    .catch(err => {
      console.log(err)
    })
}
