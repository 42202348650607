import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import DefaultLayout from "../Layout/DefaultLayout";
import Login from "../Login/Login";
import SecureRoute from "./SecureRoute";


const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/login/" component={Login} />
        <SecureRoute path="/" component={DefaultLayout} />
      </Switch>
    </Router>
  );
};

export default Routes;
