import React from 'react';
import {
  CWidgetStatsA
} from '@coreui/react'
import { CChartLine } from '@coreui/react-chartjs';


const JobsWeekly = () => {
  return (
    <CWidgetStatsA
      className="mb-4"
      color="secondary"
      value={19}
      title="Jobs Run Weekly"
      chart={
        <CChartLine
          className="mt-3 mx-3"
          style={{ height: '70px' }}
          data={{
            labels: ['Dec 10', 'Dec 17', 'Dec 24', 'Dec 31', 'Jan 7'],
            datasets: [
              {
                label: 'Jobs Run',
                backgroundColor: 'transparent',
                borderColor: 'rgba(255,255,255,.55)',
                pointBackgroundColor: '#321fdb',
                data: [65, 59, 84, 84, 51],
              },
            ],
          }}
          options={{
            plugins: {
              legend: {
                display: false,
              },
            },
            maintainAspectRatio: false,
            scales: {
              x: {
                grid: {
                  display: false,
                  drawBorder: false,
                },
                ticks: {
                  display: false,
                },
              },
              y: {
                min: 0,
                max: 90,
                display: false,
                grid: {
                  display: false,
                },
                ticks: {
                  display: false,
                },
              },
            },
            elements: {
              line: {
                borderWidth: 1,
                tension: 0.4,
              },
              point: {
                radius: 4,
                hitRadius: 10,
                hoverRadius: 4,
              },
            },
          }}
        />
      }
    />
  )
}

export default JobsWeekly