import React from 'react';
import { 
  CSidebar, CSidebarBrand, CSidebarNav,
  CNavTitle, CNavItem
} from '@coreui/react';
import { Link } from 'react-router-dom';
import UserExport from '../Dashboard/Users/UserExport';

const Sidebar = () => {
  return (
    <CSidebar
      position='fixed'
      unfoldable={false}
      visible={true}
    >
      <CSidebarBrand className="d-none d-md-flex">
        Daisy.ai Admin
      </CSidebarBrand>
      <CSidebarNav>
        <CNavTitle>Dashboards</CNavTitle>
        <CNavItem>
          <UserExport />
        </CNavItem>
      </CSidebarNav>
    </CSidebar>
  )
}

export default Sidebar;