import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  sendPasswordResetEmail
} from "firebase/auth";
import { setUID } from "./store/features/user";
import store from "./store/store";
import { setAuthToken } from "./services/Login";
import Axios from "axios";

const firebaseConfig = {
  apiKey: "AIzaSyDBEQTJ_jFhR_rIiXvGhkVG-9wspUomf3Y",
  authDomain: "earlybird-3850b.firebaseapp.com",
  projectId: "earlybird",
  storageBucket: "earlybird.appspot.com",
  messagingSenderId: "834432330684",
  appId: "1:834432330684:web:1516027e873fc98f21d9b9",
  measurementId: "G-3R5Y7GCJT5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export const login = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;
      store.dispatch(setUID(user.uid));
      return user.getIdToken();
    })
    .then((id_token) => {
      authenticateWithServer(id_token);
    })
    .catch((error) => {
      const errorCode = error.code;
      if (errorCode === "auth/invalid-email") {
        return Promise.reject("Please enter a valid email.");
      }
      if (errorCode === "auth/wrong-password") {
        return Promise.reject("Incorrect password.");
      }
    });
};

export const forgotPassword = (email) => {
  return sendPasswordResetEmail(auth, email)
  
}

export const authenticateWithServer = () => {
  const user = auth.currentUser;
  if (!user) {
    return;
  }
  user
    .getIdToken()
    .then((id_token) => {
      return Axios.post(
        "/api/login",
        {},
        {
          headers: {
            Authorization: `Bearer ${id_token}`,
          },
        }
      );
    })
    .then((res) => {
      window.location.reload();
    })
    .catch((err) => {
      console.log(err);
      auth.signOut();
    });
};

export const logout = () => {
  Axios.post("/api/logout", {})
    .then((res) => {
      console.log("logged out");
    })
    .catch((err) => {
      console.log(err);
    });
  auth.signOut();
};

onAuthStateChanged(auth, (user) => {
  console.log("onAuthStateChanged");
  if (user) {
    const uid = user.uid;
    store.dispatch(setUID(uid));
  } else {
    store.dispatch(setUID(null));
    setAuthToken(null);
  }
});

export const addInterceptor = () => {
  Axios.interceptors.response.use(
    (response) => response, // Do nothing for successful responses
    (error) => {
      if (error.response && error.response.status === 401) {
        authenticateWithServer();
      }
      return Promise.reject(error);
    }
  );
};

export { auth };
