import Axios from "axios";

export const setAuthToken = (token, UserLogout) => {
  if (token) {
    Axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    addUnauthorizedInterceptor(UserLogout)
  } else {
    delete Axios.defaults.headers.common["Authorization"];
    ejectUnauthorizedInterceptor()
  }
}

let unauthorizedInterceptor

function ejectUnauthorizedInterceptor() {
  Axios.interceptors.response.eject(unauthorizedInterceptor)
};

function addUnauthorizedInterceptor(UserLogout) {
  unauthorizedInterceptor = Axios.interceptors.response.use(response => {
    return response;
  }, error => {
    if (error.response.status === 401) {
      UserLogout()
    }
  });
}